import { picoapp } from 'picoapp'
import { size, qs } from 'martha'

import lazy from './components/lazy'
import scroll from './components/scroll'
import sticky from './components/sticky'
import drawer from './components/drawer'
import cabinet from './components/cabinet'
import marquee from './components/marquee'
import lazyVideo from './components/lazyVideo'
import carousel from './components/carousel'
import colophon from './components/colophon'
import scrollTop from './components/scrollTop'
import filter from './components/filter'
import about from './components/about'
import search from './components/search'
import searchResult from './components/searchResult'
import marqueeHover from './components/marqueeHover'
import logo from './components/logo'
import popup from './components/popup'

const components = {
  lazy,
  scroll,
  sticky,
  drawer,
  cabinet,
  marquee,
  lazyVideo,
  carousel,
  colophon,
  scrollTop,
  filter,
  about,
  search,
  searchResult,
  marqueeHover,
  logo,
  popup
}

const sizes = size()
const state = {
  ...sizes,
  mx: sizes.ww / 2,
  my: sizes.wh / 2,
  dom: {
    html: document.documentElement,
    body: document.body,
    scrollProxy: qs('.js-scroll-proxy'),
  },
  fonts: [
    // { family: 'GT Walsheim' },
    // { family: 'GT Walsheim', options: { weight: 300 } },
    // { family: 'GT Walsheim', options: { weight: 300, style: 'italic' } },
  ],
}

export default picoapp(components, state)
