import { component } from 'picoapp'
import { on, remove, add, has, qsa, qs } from 'martha'
import choozy from 'choozy'
import { ScrollToPlugin, gsap, Expo } from 'gsap/all'

export default component((node, ctx) => {
  let { drawerToggle, contentWrap, open, close, namelist, byline } =
    choozy(node)
  gsap.registerPlugin(ScrollToPlugin)
  let isAnimating = false

  on(drawerToggle, 'click', (e) => {
    console.log(e.target);
    if (isAnimating) return

    if (has(e.target, 'active')) {
      if (node.dataset.type == 'article') {
        if(byline) add(byline, 'hidden')
        if(namelist) remove(namelist, 'opacity-0')
        remove(drawerToggle, 'bg-white')
      }

      contentWrap.style.maxHeight = `0px`

      remove(open, 'hidden')
      add(close, 'hidden')
      remove(e.target, 'active')
    } else {
      //close any open drawers
      if (has(node, 'is-section')) {
        isAnimating = true

        qsa('.js-drawerToggle.active', qs('.section-articles')).forEach((drawer) => {
          let wrap = drawer.closest('.article-content')

          if (node.dataset.type == 'article') {
            if(qs('.js-byline', wrap)) add(qs('.js-byline', wrap), 'hidden')
            if(qs('.js-namelist', wrap)) remove(qs('.js-namelist', wrap), 'opacity-0')
            remove(drawer, 'bg-white')
          }

          qs('.js-contentWrap', wrap).style.maxHeight = `0px`

          remove(qs('.js-open', wrap), 'hidden')
          add(qs('.js-close', wrap), 'hidden')
          remove(drawer, 'active')
        })
      }

      if (node.dataset.type == 'article') {
        if(byline) remove(byline, 'hidden')
        if(namelist) add(namelist, 'opacity-0')
        add(drawerToggle, 'bg-white')
      }

      add(open, 'hidden')
      remove(close, 'hidden')
      add(e.target, 'active')

      contentWrap.style.maxHeight = 'unset'

      if (node.dataset.scroll == 'body') {
        gsap.to(window, {
          duration: 1,
          scrollTo: {
            y: contentWrap,
            offsetY: node.dataset.type == 'article' ? 185 : 60,
          },
          ease: Expo.easeOut,
          onComplete: () => {
            isAnimating = false
          },
        })
      } else {
        gsap.to('main', {
          duration: 1,
          scrollTo: {
            y: contentWrap,
            offsetY: node.dataset.type == 'article' ? (e.target.offsetHeight + 60) : 60,
          },
          ease: Expo.easeOut,
          onComplete: () => {
            isAnimating = false
          },
        })
      }
    }
  })
})
