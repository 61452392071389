import { remove, qsa, qs, add } from 'martha'


export default function checkPage() {
    qsa('.btn-dot', qs('footer')).forEach((dot) => {
      if (dot.getAttribute('href') == window.location.pathname) {
        add(dot, 'active')
      } else {
        remove(dot, 'active')
      }
    })
  }