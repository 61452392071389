import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa } from 'martha'
import choozy from 'choozy'
import { ScrollToPlugin, gsap, Expo, Power4 } from 'gsap/all'

export default component((node, ctx) => {
  gsap.registerPlugin(ScrollToPlugin)

  on(node, 'click', (e) => {
    gsap.to('main', {
        duration: 1,
        scrollTo: 0,
        ease: Expo.easeOut,
    })
  })
})
