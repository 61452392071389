import { component } from 'picoapp'
import { on, remove, add, qsa, toggle, qs } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { filter, zero, yearToggle, yearList, year, currentYear } = choozy(node)
  let searchParams
  let url

  const clearFilters = () => {
    let filters = qsa('.js-filter')
    filters.forEach((filter) => {
      remove(filter, 'active')
      remove(filter, 'inactive')
    })

    qsa('.js-filterItem.can-filter').forEach((filterItem) => remove(filterItem, 'hidden'))
    add(zero, 'hidden')
  }

  const checkFilters = (activeFilters) => {
    let filters = qsa('.js-filter')

    filters.forEach((filter) => {
      if (activeFilters.some((tag) => tag == filter.dataset.filter)) {
        add(filter, 'active')
        remove(filter, 'inactive')
      } else {
        remove(filter, 'active')
        add(filter, 'inactive')
      }
    })
  }

  const findMatch = (filters, type) => {
    let cards = qsa('.js-filterItem.can-filter')
    cards.forEach((card) => {
      if (!filters) {
        remove(card, 'hidden')
      } else {
        let tags
        if (type == 'year') {
          tags = card.dataset.year.split(' ')
        } else {
          tags = card.dataset.tags.split(' ')
        }

        const found = tags.some((r) => filters.includes(r))

        remove(card, 'hidden')
        if (found) {
          remove(card, 'hidden')
        } else {
          add(card, 'hidden')
        }
      }
    })
    if (!qsa('.js-filterItem.can-filter:not(.hidden)').length > 0) {
      remove(zero, 'hidden')
    } else {
      add(zero, 'hidden')
    }
  }

  const filterOnLoad = () => {
    url = new URL(window.location)
    searchParams = url.searchParams
    let existingFilters = searchParams.getAll('filter')

    if (existingFilters.length > 0) {
      checkFilters(existingFilters)
      findMatch(existingFilters)
    }
  }

  const offFilter = on(filter, 'click', (e) => {
    let curFilter = e.target.dataset.filter

    url = new URL(window.location)
    searchParams = url.searchParams

    let activeFilters = searchParams.getAll('filter')

    if (activeFilters.some((value) => value === curFilter)) {
      remove(e.target, 'active')
      add(e.target, 'inactive')
      const tags = activeFilters.filter((tag) => tag !== curFilter)
      searchParams.delete('filter')
      for (const tag of tags) searchParams.append('filter', tag)
    } else {
      add(e.target, 'active')
      remove(e.target, 'inactive')
      searchParams.append('filter', curFilter)
      url.search = searchParams.toString()
    }

    let new_url = url.toString()
    if (history.pushState) {
      window.history.pushState({ path: new_url }, '', new_url)
    }

    let newFilters = searchParams.getAll('filter')
    

    if (newFilters.length < 1) {
      clearFilters()
    } else{
      checkFilters(newFilters)
      findMatch(newFilters, 'tag')
    }    
  })

  const offYear = on(year, 'click', (e) => {
    let curYear = e.target.dataset.year

    url = new URL(window.location)
    searchParams = url.searchParams

    let activeYear = searchParams.get('year')

    searchParams.delete('year')

    if (activeYear == curYear) {
      remove(e.target, 'active')
      add(e.target, 'inactive')
    } else {
      add(e.target, 'active')
      remove(e.target, 'inactive')
      searchParams.append('year', curYear)
      url.search = searchParams.toString()
    }

    let new_url = url.toString()
    if (history.pushState) {
      window.history.pushState({ path: new_url }, '', new_url)
    }

    let newYear = searchParams.get('year')

    findMatch(newYear, 'year')
    toggle(yearList, 'hidden')

    if (newYear) {
      currentYear.innerHTML = e.target.dataset.year
    } else {
      currentYear.innerHTML = 'Latest'
    }
  })

  //toggle years
  const offToggle = on(yearToggle, 'click', () => {
    toggle(yearList, 'hidden')
  })

  filterOnLoad()

  return () => {
    offFilter()
    offYear()
    offToggle()
  }
})
