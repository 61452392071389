import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa } from 'martha'
import choozy from 'choozy'
import * as THREE from 'three'

export default component((node, ctx) => {
  let { closePopup } = choozy(node)

  let seen = sessionStorage.getItem('popup')
  let imgSrc = qs('#coverImg').dataset.src

  if (seen != 'seen') {
    setTimeout(() => {
      remove(node, 'opacity-0')
      remove(node, 'pointer-events-none')
    }, 100)

    on(closePopup, 'click', (e) => {
      add(node, 'opacity-0')
      add(node, 'pointer-events-none')

      sessionStorage.setItem('popup', 'seen')
    })
  }

  //3D BOOK
  let bookContainer = qs('.book-container')
  const scene = new THREE.Scene()
  const camera = new THREE.PerspectiveCamera(
    75,
    bookContainer.offsetWidth / bookContainer.offsetHeight,
    0.1,
    1000,
  )

  const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
  renderer.setSize(bookContainer.offsetWidth, bookContainer.offsetHeight)
  bookContainer.appendChild(renderer.domElement)

  const ambient = new THREE.AmbientLight(0x222222)
  scene.add(ambient)

  const light = new THREE.DirectionalLight(0xffffff)
  light.position.set(0, 0, 6)
  scene.add(light)

  const coverUrl = imgSrc

  const loader = new THREE.TextureLoader()
  loader.crossOrigin = 'Anonymous'
  const coverTexture = loader.load(coverUrl)

  // const loader = new THREE.TextureLoader()
  // loader.setCrossOrigin('')
  // const coverImg = qs('#bookCover').dataset.src

  const geometry = new THREE.BoxGeometry(3.5, 5, 0.5)
  // const material = new THREE.MeshPhongMaterial( { map : coverImg } );
  const material = new THREE.MeshLambertMaterial({ map: coverTexture })
  const cube = new THREE.Mesh(geometry, material)
  scene.add(cube)

  camera.position.z = 5

  function animate() {
    requestAnimationFrame(animate)
    renderer.render(scene, camera)
  }

  document.addEventListener('mousemove', (e) => {
    let maxY = 300
    let rotationY = e.pageY / 1000
    let rotationX = e.pageX / 5000
    cube.rotation.set(rotationX, rotationY, 0)
  })

  animate()
})
