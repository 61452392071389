import { component } from 'picoapp'
import choozy from 'choozy'
import flickity from 'flickity'
import { on, qs, add, remove, qsa } from 'martha'
import EmblaCarousel from 'embla-carousel'

export default component((node, ctx) => {
  let { carousel, count, background, slide, info } = choozy(node)
  let isMobile = document.documentElement.clientWidth < 850

  const embla = EmblaCarousel(carousel, { skipSnaps: false, align: 'start', loop: true });

  return () => {
      embla.destroy()
  }
})
