import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { filterOpen, filterClose } = choozy(node)

  on(filterOpen, 'click', (e) => {
    add(node, 'cabinet-open')
  })

  on(filterClose, 'click', (e) => {
    remove(node, 'cabinet-open')
  })
})
