import { component } from 'picoapp'
import choozy from 'choozy'
import gsap from 'gsap'
import inview from '../lib/inview'
import { add, remove, qsa, has, on } from 'martha'
import Player from '@vimeo/player'

export default component((node, ctx) => {
  let { vimeo, lazy } = choozy(node)
  let isPlaying
  let isMobile = document.documentElement.clientWidth < 850

  if (isMobile && has(node, 'video-nav') && has(node, 'video-desk')) {
    return
  } else if (!isMobile && has(node, 'video-nav') && !has(node, 'video-desk')) {
    return
  } else {
    const player = new Player(vimeo, {
      id: node.dataset.id,
      width: 16,
      height: 9,
      autoplay: true,
      loop: true,
      background: true,
      autopause: false,
    })

    player.on('play', function () {
      if (lazy) {
        setTimeout(() => {
          lazy.remove()
        }, 100);
      }
      isPlaying = true
    })
    player.on('pause', function () {
      isPlaying = false
    })

   const off = player.on('loaded', function () {
      ctx.on('tick', ({ wh }) => {
        if (inview(node, wh)) {
          if (isPlaying) return
          player.play()
          isPlaying = true
        } else {
          if (!isPlaying) return
          player.pause()
          isPlaying = false
        }
      })
    })

    if (!has(node, 'video-nav')) {
      return () => {
        off
        player.off('play')
        player.off('pause')
        player.off('loaded')
      }
    }
  }
})
