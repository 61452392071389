import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa, toggle } from 'martha'
import choozy from 'choozy'
import { ScrollToPlugin, gsap, Expo, Power4 } from 'gsap/all'
import checkPage from '../lib/checkPage'

export default component((node, ctx) => {
  gsap.registerPlugin(ScrollToPlugin)

  gsap.to(window, {
    duration: 0.2,
    scrollTo: 0,
    ease: Expo.easeOut,
  })

  qsa('.js-footerlink').forEach((fl) => {
    on(fl, 'click', () => {
      gsap.to(window, {
        duration: 1,
        scrollTo: 0,
        ease: Expo.easeOut,
      })
    })
  })

  on(window, 'scroll', () => {
    if (window.scrollY < 1) {
      window.scrollTo(0, 0)
      add(document.body, 'locked')
      checkPage()
    }
  })

  on(node, 'click', (e) => {
    if (!has(document.body, 'locked')) {
      add(document.body, 'locked')
      checkPage()
      gsap.to(window, {
        duration: 1,
        scrollTo: 0,
        ease: Expo.easeOut,
      })
    } else {
      remove(document.body, 'locked')

      qsa('.btn-dot', qs('footer')).forEach((dot) => {
        remove(dot, 'active')
      })

      add(node, 'active')

      gsap.to(window, {
        duration: 1,
        scrollTo: 'footer',
        ease: Expo.easeOut,
      })
    }
  })
})
