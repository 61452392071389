import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa, toggle } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { searchQuery, searchForm, yearList, yearToggle } = choozy(node)

  const offFilter = on(searchForm, 'submit', (e) => {
    e.preventDefault()
    window.location = `/search/?query=${searchQuery.value}`
  })

  return () => {
    offFilter()
  }
})
