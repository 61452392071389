import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  let { primary, copy } = choozy(node)
  let containerWidth = node.closest('.marquee-wrap').offsetWidth
  let marqueeWidth = node.offsetWidth

  if (marqueeWidth > containerWidth || node.dataset.animate == 'true') {
    node.style.width = `${primary.offsetWidth}px`

    setTimeout(() => {
      node.style.width = `${primary.offsetWidth}px`
    }, 2000)

    if (copy) {
      remove(copy, 'hidden')
    }

    if (node.dataset.hover != 'true') {
      add(node, 'animate')

      remove(node.closest('.marquee-wrap'), 'before:opacity-0')
      remove(node.closest('.marquee-wrap'), 'after:opacity-0')
    } else {
      remove(node.closest('.marquee-wrap'), 'after:opacity-0')

      on(node.closest('.js-drawerToggle'), 'mouseenter', (e) => {
        add(node, 'animate')
        remove(node.closest('.marquee-wrap'), 'before:opacity-0')
      })

      on(node.closest('.js-drawerToggle'), 'mouseleave', (e) => {
        remove(node, 'animate')
        add(node.closest('.marquee-wrap'), 'before:opacity-0')
      })
    }
  } else {
    if (node.closest('.js-drawerTitle')) {
      add(node.closest('.js-drawerTitle'), 'marquee-hidden')
    }
  }

  
})
