import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa } from 'martha'
import choozy from 'choozy'
import Fuse from 'fuse.js'

export default component((node, ctx) => {
  let { result } = choozy(node)

  const options = {
    isCaseSensitive: false,
    findAllMatches: true,
    threshold: 0.5,
    distance: 350,
    keys: ['title', 'excerpt', 'slug', 'name', 'tags', 'date'],
  }

  function getJsonFromUrl(url) {
    if (!url) url = location.search
    let query = url.substr(1)
    let result = {}
    query.split('&').forEach(function (part) {
      let item = part.split('=')
      result[item[0]] = decodeURIComponent(item[1])
    })
    return result.query
  }

  const searchQuery = getJsonFromUrl()

  if (searchQuery) {
    let articleContent = JSON.parse(node.dataset.json)
    const fuse = new Fuse(articleContent, options)

    const searchResult = fuse.search(searchQuery)
    searchResult.forEach((item) => {
      let resultSlug = item.item.slug
      let curArticle = qs(`.js-article[data-slug="${resultSlug}"`)
      remove(curArticle, 'hidden')
      add(curArticle, 'can-filter')
    })

    let articlesFound = qsa('.js-article:not(.hidden)')

    result.innerHTML = `${
      articlesFound.length == 1
        ? '1 Result for'
        : `${articlesFound.length} Results for`
    } “${searchQuery}”`
  } else{
    result.innerHTML = '0 Search Results'
  }
})
