import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa } from 'martha'
import choozy from 'choozy'

export default component((node, ctx) => {
  const randomizer = Math.floor(Math.random() * 5)

  qsa('.logo-desktop').forEach((logo, index) => {
    if (index == randomizer) {
      add(logo, 'active')
    }
  })

  qsa('.logo-mobile').forEach((logo, index) => {
    if (index == randomizer) {
      add(logo, 'active')
    }
  })
})
