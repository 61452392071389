import { component } from 'picoapp'
import { on, remove, add, has, qs, qsa } from 'martha'
import choozy from 'choozy'
import { ScrollToPlugin, gsap, Expo, Power4 } from 'gsap/all'

export default component((node, ctx) => {
  let { drawerToggle, content, contentWrap, open, close } = choozy(node)
  let contentHeight
  gsap.registerPlugin(ScrollToPlugin)

  gsap.to(window, {
    duration: .2,
    scrollTo: 0,
    ease: Expo.easeOut,
  })

  on(node, 'mouseenter', (e) => {
   add(qs('.marquee', node), 'animate')
  })

  on(node, 'mouseleave', (e) => {
    remove(qs('.marquee', node), 'animate')
   })
})
